


































import { useGetters } from '@u3u/vue-hooks'
import { defineComponent, ref } from '@vue/composition-api'

import FormInput from '@/components/g/FormInput.vue'
import Socials from '@/components/chrome/Socials.vue'

export default defineComponent({
  name: 'newsletter-form',
  components: {
    FormInput,
    Socials,
  },
  setup() {
    const email = ref('')

    // Const onSubmit = () => {
    //   console.log('Send', email.value)
    // }

    return {
      ...useGetters(['chrome']),
      email,
      // OnSubmit,
    }
  },
})
