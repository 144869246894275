






















import { defineComponent, PropType } from '@vue/composition-api'

import DashboardTile from '@/components/dashboard/Tile.vue'
import { DashboardContent, Widget } from '@/inc/types'

export default defineComponent({
  name: 'Widgets',
  components: {
    DashboardTile,
  },
  props: {
    content: {
      type: Object as PropType<DashboardContent>,
      required: true,
    },
  },
  setup() {
    const getModifiers = (widget: Widget) => {
      const { size, ratio } = widget

      return [ratio ? ratio.replace(':', '-') : '1-1', size || 'normal']
    }

    return {
      getModifiers,
    }
  },
})
