// Import Vue, { VueConstructor } from 'vue'
import { RouteConfig } from 'vue-router'

// eslint-disable-next-line
import { segments } from '@/inc/app.config'
// Import Root from '@/views/Root.vue'

// Format segments to (a|b)
// eslint-disable-next-line
const formatSegment = (key: string): string =>
  Array.isArray(segments[key])
    ? `(${(segments[key] as string[]).join('|')})`
    : (segments[key] as string)

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: 'v-Home' */ '@/views/Home.vue'),
    meta: {
      ssr: true,
      scrollTop: true,
    },
  },
  {
    path: '/actualites/',
    name: 'News',
    component: () =>
      import(/* webpackChunkName: 'v-Home' */ '@/views/News.vue'),
    meta: {
      ssr: true,
      scrollTop: true,
    },
  },
  {
    path: '/news/:slug/',
    name: 'NewsSingle',
    component: () =>
      import(/* webpackChunkName: 'v-news' */ '@/views/NewsSingle.vue'),
    meta: {
      ssr: true,
      endpoint: 'news',
      scrollTop: true,
    },
  },
  {
    path: '/flexible-content/',
    name: 'Flexible Content',
    component: () =>
      import(/* webpackChunkName: 'v-Home' */ '@/views/FlexibleContent.vue'),
    meta: {
      ssr: true,
      scrollTop: true,
    },
  },
  // TODO: move to dynamic content
  {
    path: '/:slug/:sub/',
    name: 'PageTabs',
    component: () =>
      import(/* webpackChunkName: 'v-page-tabs' */ '@/views/PageTabs.vue'),
    meta: {
      ssr: true,
      scrollTop: true,
    },
  },
  {
    path: '/:slug/:sub?',
    name: 'Page',
    component: () =>
      import(/* webpackChunkName: 'v-page' */ '@/views/Page.vue'),
    meta: {
      ssr: true,
      scrollTop: true,
    },
  },
  {
    path: '/*',
    name: 'All',
    component: () =>
      import(/* webpackChunkName: 'v-page' */ '@/views/Page.vue'),
    meta: {
      ssr: true,
      scrollTop: true,
    },
  },
]

// If multilanguage, add lang segment
// if (langAvailable.length > 1) {
//   const initialRoutes = [...routes]
//   routes = [
//     {
//       path: `/:lang(${langAvailable.join('|')})`,
//       component: Root as VueConstructor<Vue>,
//       meta: {
//         // ScrollTop all the time for all routes
//         scrollTop: true,
//       },
//       children: initialRoutes
//         .filter(r => r.meta?.root !== true)
//         .map(r => {
//           // Remove leading slash
//           r.path = r.path.replace(/^\//, '')

//           return r
//         }),
//     },
//     {
//       path: '/',
//       redirect: () => `/${langDefault}/`, // `/${navigator.language.split('-')[0] || langDefault}`
//     },
//   ]

//   routes = routes.concat(initialRoutes.filter(r => r.meta?.root === true))
// }

export { routes }
