






import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api'

import { KeyboardState } from './keyboard'

export default defineComponent({
  name: 'PingPong',
  components: {},
  props: {
    score: {
      type: Number,
      required: true,
    },
  },

  setup(props, ctx) {
    let { score } = props
    const canvas = ref<HTMLCanvasElement>()
    let context: CanvasRenderingContext2D
    let keyboard: KeyboardState
    let w = 0
    let h = 0
    let ballX = 0
    let ballY = 0
    const ballRadius = 8
    let ballSpeedX = 1
    let ballSpeedY = 1
    const g = 1.1

    const paddleSpeed = 3
    const paddleW = 60
    const paddleH = 12
    let paddleX = 0
    let paddleY = 0

    let isPlaying = true

    const init = () => {
      ballX = w / 2
      ballY = h / 2
      /* eslint-disable no-mixed-operators */
      paddleX = w / 2 + h / 2
      paddleY = h - paddleH / 2
      /* eslint-enable no-mixed-operators */
    }

    const movePaddle = () => {
      if (keyboard.pressedKey('left')) {
        paddleX -= paddleSpeed
      } else if (keyboard.pressedKey('right')) {
        paddleX += paddleSpeed
      }
    }

    const moveBall = () => {
      // Rebounds on top
      if (ballY - ballRadius < 0) {
        ballSpeedY *= -g
      }
      // Rebounds on sides
      if (ballX - ballRadius < 0 || ballX + ballRadius > w) {
        ballSpeedX *= -g
      }

      if (ballY + ballRadius > h - paddleH) {
        // DEV
        // ballSpeedY *= -1
        collide()
      }

      ballX += ballSpeedX
      ballY += ballSpeedY
    }

    const drawPaddle = () => {
      context.save()
      context.strokeStyle = 'gold'
      context.lineWidth = paddleH
      context.lineWidth = 2
      context.lineCap = 'square'
      context.beginPath()
      /* eslint-disable no-mixed-operators */
      context.moveTo(paddleX - paddleW / 2, paddleY - paddleH / 2 - 1)
      context.lineTo(paddleX + paddleW / 2, paddleY - paddleH / 2 - 1)
      context.lineTo(paddleX + paddleW / 2, paddleY + paddleH / 2 - 1)
      context.lineTo(paddleX - paddleW / 2, paddleY + paddleH / 2 - 1)
      context.lineTo(paddleX - paddleW / 2, paddleY - paddleH / 2 - 1)
      context.stroke()
      context.restore()
    }

    const drawBall = () => {
      context.save()
      context.strokeStyle = 'pink'
      context.lineWidth = 2
      context.lineCap = 'square'
      context.beginPath()
      context.moveTo(ballX, ballY - ballRadius)
      context.lineTo(ballX + ballRadius, ballY)
      context.lineTo(ballX, ballY + ballRadius)
      context.lineTo(ballX - ballRadius, ballY)
      context.lineTo(ballX, ballY - ballRadius)
      context.stroke()
      context.restore()
    }

    const collide = () => {
      if (
        ballX - ballRadius < paddleX - paddleW / 2 ||
        ballX + ballRadius > paddleX + paddleW / 2
      ) {
        // Set ball and players to the center
        // ball.x = canvas.width / 2;
        // ball.y = canvas.height / 2;
        // player.y = canvas.height / 2 - PLAYER_HEIGHT / 2;
        // computer.y = canvas.height / 2 - PLAYER_HEIGHT / 2;
        // Reset speed
        // ball.speed.x = 2;
        // console.log('END')
        isPlaying = false
        drawBg()
      } else {
        // Increase speed and change direction
        ballSpeedY *= -g
        ballSpeedX *= g

        score += 1
        ctx.emit('pong', score)
      }
    }

    const drawBg = () => {
      context.fillStyle = 'white'
      context.fillRect(0, 0, w, h)
    }

    const draw = () => {
      drawBg()

      movePaddle()
      moveBall()

      if (isPlaying) {
        drawPaddle()
        drawBall()
        window.requestAnimationFrame(draw)
      }
    }

    onMounted(() => {
      if (!canvas.value) {
        return
      }

      const { devicePixelRatio: dpr } = window
      keyboard = new KeyboardState()
      context = canvas.value.getContext('2d') as CanvasRenderingContext2D
      w = canvas.value.offsetWidth
      h = canvas.value.offsetHeight

      canvas.value.style.width = `${w}px`
      canvas.value.style.height = `${h}px`
      canvas.value.width = w * dpr
      canvas.value.height = h * dpr

      context.scale(dpr, dpr)

      init()
      draw()
    })

    onUnmounted(() => {
      keyboard?.destroy()
    })

    return {
      canvas,
    }
  },
})
