





























import { defineComponent } from '@vue/composition-api'
import { useGetters, useActions } from '@u3u/vue-hooks'

const components = {}
const Default = {
  template: '<div style="padding: 4rem;">No panel found…</div>',
}

export default defineComponent({
  name: 'UISidePanel',
  setup() {
    const { hidePanel } = useActions('sidePanel', ['hidePanel'])
    const { panels, hasPanels } = useGetters('sidePanel', [
      'panels',
      'hasPanels',
    ])
    const close = hidePanel
    const getComponent = component => {
      const { slug, template } = component

      if (template) {
        return template
      }

      const registered = components[slug]

      if (registered) {
        return registered
      }

      console.warn('[SidePanel] No valid component found')

      return Default
    }

    return {
      close,
      getComponent,
      panels,
      hasPanels,
    }
  },
})
