


























import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { gsap } from 'gsap'
import Slidy from 'epic-slidy'

export default defineComponent({
  props: {
    content: Object,
  },
  setup(props) {
    let slider: Slidy
    const list = ref<null | HTMLElement>(null)
    const image = ref<[] | HTMLPictureElement[]>([])
    const caption = ref<null | string>(null)

    onMounted(() => {
      const transition = (
        currentSlide: HTMLElement | HTMLElement[],
        newSlide: HTMLElement | HTMLElement[]
      ) =>
        new Promise<void>(resolve => {
          const tl = gsap.timeline({
            onComplete: () => {
              resolve()
            },
          })

          caption.value = props?.content?.pictures[slider.newIndex].caption

          tl.add('transition')
            .set(newSlide, {
              opacity: 0,
              zIndex: 3,
            })
            .to(
              currentSlide,
              {
                duration: 0.5,
                opacity: 0,
                ease: 'power4.inOut',
              },
              'transition'
            )
            .to(
              newSlide,
              {
                duration: 0.5,
                opacity: 1,
                ease: 'power4',
              },
              'transition'
            )
        })

      const shouldInteract = props.content?.pictures.length > 1

      slider = new Slidy(list.value as HTMLElement, {
        controls: shouldInteract
          ? '<svg class="gallery-controls__item__icon icon" viewBox="0 0 22 14" xmlns="http://www.w3.org/2000/svg"><path d="m10.9935027 8.57585231-8.01825272-8.06173561c-.43808573-.44119276-1.07826719-.61515572-1.67939419-.45635871-.60112701.15879701-1.07187406.62622895-1.2349153 1.22621872-.16304124.59998976.00639318 1.24138464.44447895 1.68257736l10.48808326 10.54026273 10.4967798-10.53156614c.6796233-.67962338.6796233-1.78151058 0-2.46113396-.6796234-.67962337-1.7815106-.67962337-2.461134 0z"/></svg>' // eslint-disable-line max-len
          : false,
        click: false,
        drag: shouldInteract,
        namespace: 'gallery',
        nav: true,
        loop: false,
        pagination: true,
        swipe: shouldInteract,
        transition,
      })

      slider.init()

      caption.value = props?.content?.pictures[0].caption
    })

    onUnmounted(() => {
      slider.destroy()
    })

    return {
      caption,
      image,
      list,
    }
  },
})
