import { logger } from '@/inc/utils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
export default function vapperClient(ctx: any): void {
  const { VUE_APP_BROWSERSLIST_ENV } = process.env
  logger.trace('CLIENT', VUE_APP_BROWSERSLIST_ENV, ctx)

  const initApp = (): void => {
    // "Dynamically" load `support/modern` or `support/legacy`
    import(`@/inc/polyfills/${VUE_APP_BROWSERSLIST_ENV}.ts`).then(
      ({ default: polyfills }) => polyfills.init()
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const domReady = (): Promise<void | Event> =>
    new Promise(resolve => {
      if (
        document.readyState === 'complete' ||
        document.readyState !== 'loading'
      ) {
        resolve()
      } else {
        document.addEventListener('DOMContentLoaded', resolve)
      }
    })

  Promise.all([domReady()]).then(initApp)
}
