























































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { gsap } from 'gsap'
import CustomEase from 'gsap/dist/CustomEase.js'

export default defineComponent({
  name: 'CtasGrid',
  props: {
    content: Object,
  },
  setup() {
    const item = ref<HTMLElement | null>(null)
    const tl = ref()
    tl.value = gsap.timeline()

    onMounted(() => {
      tl.value.set(item.value, {
        opacity: 0,
        x: 100,
      })
    })

    const onAppear = isInViewport => {
      const customEase = CustomEase.create(
        'custom',
        'M0,0 C0.262,0.75 -0.198,0.998 1,1 '
      )

      if (isInViewport) {
        tl.value.to(
          item.value,
          {
            duration: 0.5,
            opacity: 1,
            stagger: 0.1,
            x: 0,
            ease: customEase,
          },
          '<1'
        )
      }
    }

    return {
      onAppear,
      item,
    }
  },
})
