














































































import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { gsap } from 'gsap'
import Card from '@/components/Card.vue'
import Slidy, { TransitionInfos } from 'epic-slidy'

import { isMoreThanOrEqual, onResize } from '@/composables/mq'

export default defineComponent({
  name: 'related-news',
  components: {
    Card,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isReady = ref(false)
    let slider: Slidy
    const list = ref<HTMLElement | null>(null)
    const container = ref<HTMLElement | null>(null)
    const number = ref(1)
    const current = ref(0)
    const currentItem = ref<HTMLElement | null>(null)

    const prev = () => {
      slider.slidePrev('controls')
    }

    const next = () => {
      slider.slideNext('controls')
    }

    onMounted(() => {
      onResize(
        window.innerWidth,
        window.innerHeight,
        window.innerWidth / window.innerHeight
      )

      isReady.value = true

      setTimeout(() => {
        if (list.value) {
          console.log('-- RNSlider Ready --')
          const items = list.value!.querySelectorAll('li')

          currentItem.value = items[current.value]

          number.value = Math.floor(
            list.value!.clientWidth / items[items.length - 1].clientWidth
          )

          const transition = (
            currentSlide: HTMLElement,
            newSlide: HTMLElement,
            infos,
            { newIndex }: TransitionInfos
          ) => {
            const tl = gsap.timeline()
            current.value = newIndex

            return tl
              .add('transition')
              .to(
                list.value,
                {
                  x: currentItem.value!.offsetWidth * -current.value,
                  duration: 0.5,
                },
                'transition'
              )
              .then()
          }

          const shouldInteract = props.content?.cards.length > number.value

          slider = new Slidy(list.value as HTMLElement, {
            controls: false,
            click: false,
            drag: shouldInteract,
            namespace: 'rn-slider',
            swipe: shouldInteract,
            loop: false,
            transition,
          })

          slider.on('beforeSlide', infos => {
            if (infos.direction === 'prev') {
              gsap.set(slider.items[infos.newIndex], {
                opacity: 1,
              })
            } else {
              gsap.set(slider.items[infos.currentIndex], {
                opacity: 0,
              })
            }
          })

          slider.init()
        }
      }, 100)
    })

    onUnmounted(() => {
      if (slider) {
        slider.destroy()
      }
    })

    return {
      list,
      container,
      prev,
      next,
      current,
      number,
      isMoreThanOrEqual,
      isReady,
    }
  },
})
