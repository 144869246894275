















import { defineComponent, ref, PropType } from '@vue/composition-api'
import { CtaContent } from '@/inc/types'

export default defineComponent({
  name: 'cta',
  props: {
    content: {
      type: Object as PropType<CtaContent>,
      required: true,
    },
  },
  setup() {
    const isHover = ref(false)
    const mouseOver = () => {
      isHover.value = true
    }
    const mouseLeave = () => {
      isHover.value = false
    }

    return {
      isHover,
      mouseLeave,
      mouseOver,
    }
  },
})
