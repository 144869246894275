
































































































import { defineComponent } from '@vue/composition-api'
import DuoHeader from '@/components/duo/Header.vue'

export default defineComponent({
  name: 'WidgetDuo',
  components: {
    DuoHeader,
  },
  props: {
    content: Object,
  },
})
