import { Module } from 'vuex'
import { version as pkgVersion } from '../../../../package.json'
import { RootState } from '@/inc/store'

export interface VersionState {
  version: string
}

const state: VersionState = { version: pkgVersion }

export const version: Module<VersionState, RootState> = {
  state,
}
