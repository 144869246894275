





















































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api'
import MobilityCta from '@/components/mobility/Cta.vue'

import { isLessThan, isMoreThanOrEqual, onResize } from '@/composables/mq'

export default defineComponent({
  name: 'ChromeBurgerSection',
  components: {
    MobilityCta,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    listName: {
      type: String,
      required: true,
    },
    opened: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const isReady = ref(false)
    const isSubListOpen = ref(false)

    const close = () => {
      ctx.emit('close')
      isSubListOpen.value = false
    }

    const toggleChild = e => {
      if (isSubListOpen.value === e) {
        isSubListOpen.value = false
      } else {
        isSubListOpen.value = e
      }
    }

    const checkOpened = computed(() => props.opened)

    watch(checkOpened, value => {
      if (!value && checkOpened.value === false) {
        isSubListOpen.value = false
      }
    })

    onMounted(() => {
      onResize(
        window.innerWidth,
        window.innerHeight,
        window.innerWidth / window.innerHeight
      )
      isReady.value = true
    })

    return {
      close,
      isLessThan,
      isMoreThanOrEqual,
      isReady,
      isSubListOpen,
      toggleChild,
    }
  },
})
