






























































import { defineComponent } from '@vue/composition-api'
import ChromeBurgerSection from '@/components/chrome/header/BurgerSection.vue'
import NewsletterForm from '@/components/chrome/NewsletterForm.vue'

export default defineComponent({
  name: 'burger-menu',
  components: {
    ChromeBurgerSection,
    NewsletterForm,
  },
  props: ['content', 'opened'],

  setup(props, ctx) {
    const close = () => {
      ctx.emit('close')
    }

    return {
      close,
    }
  },
})
