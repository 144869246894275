




































import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

// eslint-disable-next-line

import DevGrid from '@/components/DevGrid.vue'
import EnvSwitcher from '@/components/EnvSwitcher.vue'
import ChromeHeader from '@/components/chrome/Header.vue'
import ChromeFooter from '@/components/chrome/Footer.vue'
import UILoadingGlobal from '@/components/ui/LoadingGlobal.vue'
import UISidePanel from '@/components/ui/SidePanel.vue'
import { onResize } from '@/composables/mq'
import NotFound from '@/views/NotFound.vue'
import { StorageUtils } from '@/inc/plugins/storage'
import { isUnderMaintenance } from '@/inc/app.config'
import favicon from '@/inc/custom/favicon'

export default Vue.extend({
  name: 'app',
  components: {
    ChromeHeader,
    ChromeFooter,
    DevGrid,
    EnvSwitcher,
    NotFound,
    UILoadingGlobal,
    UISidePanel,
  },
  data() {
    return {
      hasError: false,
      isUnderMaintenance,
      body: {} as HTMLElement,
    }
  },
  computed: {
    ...mapGetters(['meta', 'chrome', 'template']),
  },
  methods: {
    ...mapMutations({
      setResource: 'SET_RESOURCE',
    }),
    ...mapActions(['fetchChrome']),
    onResize(w, h, r) {
      onResize(w, h, r)
    },
  },
  // Needed for SSR rendering
  // https://vapperjs.org/data-prefetching.html#data-prefetch
  // BUT does not work with composition-api + defineComponent
  needSerialize: true,
  async created() {
    this.$logger.trace('[app:created]', this.$$type)

    if (!this.$isServer) {
      this.hasError =
        document.querySelector('[data-server-rendered].error') !== null
    }

    if (this.hasError) {
      return
    }

    // Pass option to root context…
    // More convenient because $options.$storage could be undefined
    this.$root.$storage = this.$root.$options.$storage as StorageUtils

    await this.fetchChrome({ lang: this.$i18n.locale, type: this.$$type })

    // Get API response serialized (thanks to `needSerialize`)
    // Or from router (e.g.: SSR error, SPA Fallback)
    // Init store resource
    if (this.$isServer) {
      this.setResource(this.$root.$options.$resource)
    } else if (!this.$isServer && document.body.dataset.rendering === 'SPA') {
      this.setResource(this.$router.$resource)
    }
  },
  mounted() {
    this.$logger.trace('[app:mounted]')
    this.body = document.body
    this.$on('scroll-disabled', () => (this.body.style.overflow = 'hidden'))
    this.$on('scroll-enabled', () => this.body.removeAttribute('style'))
  },
  watch: {
    $route() {
      // Update store
      this.setResource(this.$router.$resource)
    },
  },
  head() {
    return {
      ...this.meta,
      link: favicon?.link.concat(this.meta?.link || []),
      meta: favicon?.meta.concat(this.meta?.meta || []),
    }
  },
})
