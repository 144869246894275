







import { defineComponent, ref, onMounted, Ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import copy from 'copy-to-clipboard'

interface ChromeEmbed {
  embedWidget: string
}

export default defineComponent({
  name: 'DashboardPanelEmbed',
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const root = ref<HTMLElement>()
    const { chrome } = useGetters(['chrome']) as Record<
      string,
      Ref<ChromeEmbed>
    >

    const outer = document.createElement('div')
    const button = document.createElement('button')
    const span = document.createElement('span')

    outer.classList.add('btn-outer')
    button.classList.add('btn')
    span.classList.add('btn__label')
    span.textContent = 'Copier'

    button.appendChild(span)
    outer.appendChild(button)

    onMounted(() => {
      if (!root.value) {
        return
      }

      const pres = [...root.value.querySelectorAll<HTMLPreElement>('pre')]

      pres.forEach(pre => {
        const block = outer.cloneNode(true) as HTMLDivElement
        const btn = block.querySelector('button') as HTMLButtonElement

        btn.addEventListener('click', () => {
          console.log('WHOOOOOP', pre.textContent)
          copy(pre.textContent as string)

          pre.classList.add('copied')

          setTimeout(() => {
            pre.classList.remove('copied')
          }, 200)
        })

        pre.parentNode!.insertBefore(block, pre.nextSibling)
      })
    })

    return {
      chrome,
      root,
    }
  },
})
