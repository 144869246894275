



































import { defineComponent, PropType } from '@vue/composition-api'
import { useActions } from '@u3u/vue-hooks'

import DashboardWidget from '@/components/dashboard/Widget.vue'
import DashboardPanelHelp from '@/components/dashboard/PanelHelp.vue'
import DashboardPanelEmbed from '@/components/dashboard/PanelEmbed.vue'
import { getApiUrl } from '@/inc/app.config'
import { cache, logger } from '@/inc/utils'
import { Widget } from '@/inc/types'

export default defineComponent({
  name: 'DashboardTile',
  components: {
    DashboardWidget,
  },
  props: {
    widget: {
      type: Object as PropType<Widget>,
      required: true,
    },
  },

  setup() {
    const { showPanel } = useActions('sidePanel', ['showPanel'])

    const fetchWidget = (url: string) => {
      const fullUrl = getApiUrl() + url

      logger.info('[fetchWidget]', fullUrl)

      return cache.fetch(fullUrl, fullUrl)
    }

    const onHelp = (url?: string) => {
      console.log('onHelp', url)
      if (!url) {
        return
      }

      showPanel({
        component: { template: DashboardPanelHelp },
        content: fetchWidget(url),
      })
    }

    const onEmbed = (snippet: string) => {
      showPanel({
        component: { template: DashboardPanelEmbed },
        content: { snippet },
      })
    }

    return {
      onHelp,
      onEmbed,
    }
  },
})
