




















import { defineComponent, ref } from '@vue/composition-api'
import MobilityCta from '@/components/mobility/Cta.vue'

export default defineComponent({
  name: 'ChromeHeaderNavChildren',
  components: {
    MobilityCta,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const isListOpen = ref(false)
    const current = ref()

    const close = () => {
      ctx.emit('close')
    }

    return {
      current,
      close,
      isListOpen,
    }
  },
})
