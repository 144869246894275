



































import { defineComponent, onMounted, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'g-pattern',
  props: {
    content: Object,
  },
  setup() {
    const selectedImage = ref()
    const imgData = ref(['1', '2', '3', '4', '5'])

    onMounted(() => {
      selectedImage.value = randomItem(imgData.value)
    })

    const randomItem = items => items[Math.floor(Math.random() * items.length)]

    return {
      imgData,
      selectedImage,
    }
  },
})
