















































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { gsap } from 'gsap'
import CustomEase from 'gsap/dist/CustomEase.js'
import DuoServices from '@/components/duo/Services.vue'

export default defineComponent({
  name: 'TextDuo',
  components: {
    DuoServices,
  },
  props: {
    content: Object,
  },
  setup() {
    const duoContent = ref<HTMLElement | null>(null)
    const duoHeader = ref<HTMLElement | null>(null)
    const tl = ref()
    tl.value = gsap.timeline()

    onMounted(() => {
      tl.value
        .set(duoHeader.value, {
          opacity: 0,
          y: 30,
        })
        .set(duoContent.value, {
          opacity: 0,
          y: 50,
        })
    })

    const onAppear = isInViewport => {
      const customEase = CustomEase.create(
        'custom',
        'M0,0 C0.262,0.75 -0.198,0.998 1,1 '
      )

      if (isInViewport) {
        tl.value
          .to(
            duoHeader.value,
            {
              duration: 0.8,
              opacity: 1,
              y: 0,
              ease: customEase,
            },
            '<.2'
          )
          .to(
            duoContent.value,
            {
              duration: 1,
              opacity: 1,
              y: 0,
              ease: customEase,
            },
            '<.2'
          )
      }
    }

    return {
      duoContent,
      duoHeader,
      onAppear,
    }
  },
})
