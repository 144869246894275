var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content)?_c('div',{directives:[{name:"aware",rawName:"v-aware",value:({
    appear: {
      once: true,
      threshold: [0.1, 0.75, 1],
    },
  }),expression:"{\n    appear: {\n      once: true,\n      threshold: [0.1, 0.75, 1],\n    },\n  }"}],staticClass:"keypoints",on:{"appear":_vm.onAppear}},[_c('div',{staticClass:"keypoints-inner mb-xxl pt-xl pb-xl"},[_c('div',{staticClass:"keypoints__header wrapper"},[(_vm.content.headline)?_c('div',{staticClass:"keypoints__header__headline",domProps:{"innerHTML":_vm._s(_vm.content.headline)}}):_vm._e(),(_vm.content.title)?_c('h2',{staticClass:"keypoints__header__title h2",domProps:{"innerHTML":_vm._s(_vm.content.title)}}):_vm._e()]),_c('div',{ref:"container",staticClass:"keypoints__slider mt-xxl"},[_c('div',{staticClass:"keypoints__nav"},[_c('g-action',{attrs:{"content":{
            tag: 'button',
            label: 'Précédent',
            modifiers: ['slider', 'colored'],
            icon: 'arrowLeft',
          },"aria-label":"Précédent","disabled":_vm.current === 0},nativeOn:{"click":function($event){return _vm.prev.apply(null, arguments)}}}),_c('g-action',{attrs:{"content":{
            tag: 'button',
            label: 'Suivante',
            modifiers: ['slider', 'colored'],
            icon: 'arrowRight',
          },"aria-label":"Suivant","disabled":_vm.current >= _vm.content.items.length - 1},nativeOn:{"click":function($event){return _vm.next.apply(null, arguments)}}})],1),_c('ul',{ref:"list",staticClass:"keypoints__list mt-xl"},_vm._l((_vm.content.items),function(item,index){return _c('li',{key:("item-" + index),staticClass:"keypoints__item"},[_c('div',{staticClass:"keypoints__item-inner"},[_c('div',{staticClass:"keypoints__item__title",domProps:{"innerHTML":_vm._s(item.title)}}),_c('div',{staticClass:"keypoints__item__subtitle",domProps:{"innerHTML":_vm._s(item.subtitle)}})])])}),0)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }