/* eslint-disable indent */
import { AxiosResponse, AxiosRequestConfig } from 'axios'
import VueRouter, { Route } from 'vue-router'

import { endpointDefault, getApiUrl, segments } from '@/inc/app.config'
import { cache, logger } from '@/inc/utils'
import { ResourceArchive, ResourceSingle } from '@/inc/store/modules/resource'

/**
 * Parse route to build an API request like ${api}/${endpoint}/${resource}.
 */
export const parseRoute = (to: Route) => {
  const { path, meta, params, query } = to
  const api = meta?.api || getApiUrl()
  let endpoint = endpointDefault
  let resource = path

  // Dev
  // const allowedParams = {
  //   news: ['categories', 'categories[]'],
  // }

  if (params?.lang) {
    // Remove lang from path
    resource = resource.replace(new RegExp(`^/${params.lang}`), '')
  }

  if (meta?.endpoint) {
    // Set API endpoint
    ;({ endpoint } = meta)
    // Remove endpoint from resource path
    // Check for a named route params or a registered segment
    /* eslint-disable indent */
    const pattern =
      params.endpoint || segments[endpoint]
        ? `(${endpoint}|${
            Array.isArray(segments[endpoint])
              ? (segments[endpoint] as string[]).join('|')
              : (segments[endpoint] as string)
          })`
        : endpoint
    /* eslint-enable indent */
    resource = resource.replace(new RegExp(`^/${pattern}`), '')
  }

  if (query?.preview === 'true') {
    // Manage preview
    const { page_id: pageId, id: postId, p: postType } = query

    if (pageId) {
      resource = `/${pageId}`
    }

    if (postType && postId) {
      endpoint = postType as string
      resource = `/${postId}`
    }
  }

  // Dev
  // if (query.categories) {
  //   const categories1: string[] = (query.categories as string).split(',')
  //   resource += `?categories[]=${categories1.join('&categories[]=')}`
  // }

  if (query && query['categories[]']) {
    const categories2: string[] = Array.isArray(query['categories[]'])
      ? (query['categories[]'] as string[])
      : [query['categories[]']]
    resource += `?categories[]=${categories2.join('&categories[]=')}`
  }

  resource = resource.replace(/^\//, '')

  return {
    api,
    endpoint,
    resource,
  }
}

/**
 * Save resource to context
 */
const save = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ctx: any | VueRouter,
  data: ResourceArchive | ResourceSingle,
  ssr: boolean
) => {
  if (ssr) {
    // Save to server response
    ctx.$resource = data
  } else {
    // Can not commit the store here
    // It's not compatible with VueJS lifecycle: content changed but views/components did not yet…
    // So there are matching error between new content from store and existing (old) components
    // We should use route watcher (App)
    // ctx.app.$store.commit('SET_RESOURCE', data)

    // Save to router object
    ctx.$resource = data
  }
}

/**
 * Main fetch based on routing.
 * Executed SSR (or client side as a fallback)
 * and then on navigation.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function fetch(to: Route, ctx: any | VueRouter, ssr = false) {
  const config: AxiosRequestConfig = {}
  const { fullPath, meta, query } = to

  // No fetch for static routes
  // eslint-disable-next-line
  // TODO: what about metadata (title, head, …)
  if (meta && meta.static) {
    ctx.$resource = {
      content: {},
      languages: {},
    }

    return
  }

  const { api, endpoint, resource } = parseRoute(to)

  if (query && query.preview === 'true') {
    // Preview header
    config.headers = {
      'x-preview': true,
    }
  }

  const url = `${api}/${endpoint}/${resource}`

  logger.info('[fetch]', url)

  try {
    if (ssr) {
      // No cache server-side because instance is "shared" across all clients, forever :)
      cache.data.clear()
    }
    const response = (await cache.fetch(
      fullPath,
      url,
      config
    )) as AxiosResponse<ResourceArchive | ResourceSingle>

    // Nested, so what?
    // if (params.nested || meta.nested) {}

    save(ctx, response.data, ssr)
  } catch (error) {
    const { response } = error

    if (response && response.status === 404) {
      save(ctx, response.data, ssr)

      if (ssr) {
        // Add 404 status to the server response
        ctx.statusCode = response.status
        ctx.statusMessage = response.statusText
      }
    } else {
      logger.error('[fetch]', error)
    }
  }
}

/**
 * Fetch resource from API directly
 * without being related on routing.
 */
export function fetchLive(to: Route) {
  const { fullPath } = to
  const { api, endpoint, resource } = parseRoute(to)
  const url = `${api}/${endpoint}/${resource}`

  logger.info('[fetchLive]', url)

  return cache.fetch(fullPath, url)
}
