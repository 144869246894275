






























import { useGetters } from '@u3u/vue-hooks'
import { defineComponent, onMounted, ref } from '@vue/composition-api'

import ChromeHeaderNav from '@/components/chrome/header/Nav.vue'
import ChromeBurgerMenu from '@/components/chrome/header/BurgerMenu.vue'

import { gsap } from 'gsap'
import CustomEase from 'gsap/dist/CustomEase.js'

export default defineComponent({
  name: 'ChromeHeader',
  components: {
    ChromeHeaderNav,
    ChromeBurgerMenu,
  },
  setup() {
    const burgerMenuOpened = ref(false)
    const logo = ref<HTMLElement | null>(null)
    const trigger = ref<HTMLElement | null>(null)
    const tl = ref()
    const tlMenu = ref()
    const toggleBurgerMenu = () => {
      burgerMenuOpened.value = !burgerMenuOpened.value

      if (burgerMenuOpened.value) {
        document.body.classList.add('no-scroll')

        tlMenu.value = gsap.timeline()

        const mobility = document.querySelectorAll(
          '.burger-menu .mobility .section__link'
        )
        const map = document.querySelectorAll(
          '.burger-menu .map .section__link'
        )
        const data = document.querySelectorAll(
          '.burger-menu .data .section__link'
        )
        const secondary = document.querySelectorAll(
          '.burger-menu .secondary .burger-menu__section__link'
        )
        const newsletter = document.querySelector('.burger-menu__newsletter')

        const duoContent = document.querySelectorAll(
          '.burger-menu .section__content'
        )

        const customEase = CustomEase.create(
          'custom',
          'M0,0 C0.262,0.75 -0.198,0.998 1,1 '
        )

        tlMenu.value
          .add('start-menu')
          .set(mobility, {
            opacity: 0,
            y: 50,
          })
          .set(map, {
            opacity: 0,
            y: 50,
          })
          .set(data, {
            opacity: 0,
            y: 50,
          })
          .set(secondary, {
            opacity: 0,
            y: 50,
          })
          .set(newsletter, {
            opacity: 0,
            y: 50,
          })
          .set(duoContent, {
            opacity: 0,
            y: 50,
          })

        tlMenu.value
          .to(
            mobility,
            {
              duration: 1,
              opacity: 1,
              stagger: 0.08,
              y: 0,
              ease: customEase,
            },
            '<+.2'
          )
          .to(
            duoContent,
            {
              duration: 1,
              opacity: 1,

              y: 0,
              ease: customEase,
            },
            '<+.01'
          )
          .to(
            map,
            {
              duration: 1,
              opacity: 1,
              stagger: 0.08,
              y: 0,
              ease: customEase,
            },
            '<+.01'
          )
          .to(
            data,
            {
              duration: 1,
              opacity: 1,
              stagger: 0.08,
              y: 0,
              ease: customEase,
            },
            '<+.01'
          )
          .to(
            secondary,
            {
              duration: 1,
              opacity: 1,
              stagger: 0.08,
              y: 0,
              ease: customEase,
            },
            '<+.01'
          )
          .to(
            newsletter,
            {
              duration: 1,
              opacity: 1,
              stagger: 0.08,
              y: 0,
              ease: customEase,
            },
            '<+.3'
          )
      } else {
        document.body.classList.remove('no-scroll')
      }
    }

    tl.value = gsap.timeline()

    onMounted(() => {
      const navItem = document.querySelectorAll('.nav__item')

      const customEase = CustomEase.create(
        'custom',
        'M0,0 C0.262,0.75 -0.198,0.998 1,1 '
      )

      tl.value
        .set(logo.value, {
          opacity: 0,
          y: -20,
        })
        .set(navItem, {
          opacity: 0,
          y: -20,
        })
        .set(trigger.value, {
          opacity: 0,
          y: -20,
        })
        .to(
          logo.value,
          {
            duration: 1,
            opacity: 1,
            y: 0,
            ease: customEase,
          },
          '<0.5'
        )
        .to(
          navItem,
          {
            duration: 1,
            opacity: 1,
            stagger: 0.05,
            y: 0,
            ease: customEase,
          },
          '<0.2'
        )
        .to(
          trigger.value,
          {
            duration: 1,
            opacity: 1,
            y: 0,
            ease: customEase,
          },
          '<0.2'
        )
    })

    return {
      burgerMenuOpened,
      logo,
      trigger,
      toggleBurgerMenu,
      ...useGetters(['chrome']),
    }
  },
})
