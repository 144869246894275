var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content && _vm.isReady)?_c('div',{staticClass:"related-news"},[_c('div',{staticClass:"related-news-inner mb-xxxl pt-xl pb-xxl"},[_c('div',{ref:"container",staticClass:"related-news__slider mt-xxl"},[_c('div',{staticClass:"related-news__content"},[(_vm.content.title)?_c('h2',{staticClass:"related-news__title mb-m h2",domProps:{"innerHTML":_vm._s(_vm.content.title)}}):_vm._e(),_c('div',{staticClass:"related-news__nav"},[_c('g-action',{attrs:{"content":{
              tag: 'button',
              label: 'Précédent',
              modifiers: ['slider', 'colored'],
              icon: 'arrowLeft',
            },"aria-label":"Précédent","disabled":_vm.current === 0},nativeOn:{"click":function($event){return _vm.prev.apply(null, arguments)}}}),_c('g-action',{attrs:{"content":{
              tag: 'button',
              label: 'Suivante',
              modifiers: ['slider', 'colored'],
              icon: 'arrowRight',
            },"aria-label":"Suivant","disabled":_vm.current >= _vm.content.cards.length - 1},nativeOn:{"click":function($event){return _vm.next.apply(null, arguments)}}})],1),(_vm.isMoreThanOrEqual('m'))?_c('a',{staticClass:"related-news__link",attrs:{"href":_vm.content.allLink.url}},[_vm._v(" "+_vm._s(_vm.content.allLink.label)+" ")]):_vm._e()]),(_vm.isMoreThanOrEqual('m'))?_c('ul',{ref:"list",staticClass:"related-news__list mt-xl"},_vm._l((_vm.content.cards),function(card,index){return _c('li',{key:("cards-" + index),staticClass:"related-news__card"},[_c('Card',{attrs:{"content":card}})],1)}),0):_c('ul',{staticClass:"related-news__list is-mobile mt-xl"},_vm._l((_vm.content.cards),function(card,index){return _c('li',{key:("cards-" + index),staticClass:"related-news__card"},[_c('Card',{attrs:{"content":card}})],1)}),0),(!_vm.isMoreThanOrEqual('m'))?_c('a',{staticClass:"related-news__link",attrs:{"href":_vm.content.allLink.url}},[_vm._v(" "+_vm._s(_vm.content.allLink.label)+" ")]):_vm._e()])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }