














































































import { useGetters } from '@u3u/vue-hooks'
import { defineComponent } from '@vue/composition-api'

import NewsletterForm from '@/components/chrome/NewsletterForm.vue'

export default defineComponent({
  name: 'ChromeFooter',
  components: {
    NewsletterForm,
  },
  setup() {
    return {
      ...useGetters(['chrome']),
    }
  },
})
