





















import { defineComponent } from '@vue/composition-api'
import DuoHeader from '@/components/duo/Header.vue'

export default defineComponent({
  name: 'MapDuo',
  components: {
    DuoHeader,
  },
  props: {
    content: Object,
  },
  setup() {
    const slugify = str => {
      let slug = ''
      slug = str.replace(/^\s+|\s+$/g, '')

      // Make the string lowercase
      slug = slug.toLowerCase()

      // Remove accents, swap ñ for n, etc
      const from =
        'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;'
      const to =
        'AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------'
      for (let i = 0, l = from.length; i < l; i++) {
        slug = slug.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
      }

      // Remove invalid chars
      slug = slug
        .replace(/[^a-z0-9 -]/g, '')
        // Collapse whitespace and replace by -
        .replace(/\s+/g, '-')
        // Collapse dashes
        .replace(/-+/g, '-')

      return slug
    }

    return {
      slugify,
    }
  },
})
