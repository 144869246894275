






























































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'g-download-link',
  props: {
    content: Object,
  },
})
