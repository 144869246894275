var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content)?_c('div',{directives:[{name:"aware",rawName:"v-aware",value:({
    appear: {
      once: true,
      threshold: [0.1, 0.75, 1],
    },
  }),expression:"{\n    appear: {\n      once: true,\n      threshold: [0.1, 0.75, 1],\n    },\n  }"}],staticClass:"duo",class:{
    'duo-illustration': _vm.content.illustration,
    'has-label': _vm.content.headline,
  },attrs:{"id":_vm.content.slug},on:{"appear":_vm.onAppear}},[_c('div',{staticClass:"duo-inner wrapper mb-xxxl"},[_c('div',{ref:"duoHeader",staticClass:"duo__header"},[_c('g-pattern',{staticClass:"pattern-top"}),(_vm.content.headline)?_c('div',{staticClass:"duo__header__headline",domProps:{"innerHTML":_vm._s(_vm.content.headline)}}):_vm._e(),(_vm.content.title)?_c('h2',{staticClass:"duo__header__title h2",domProps:{"innerHTML":_vm._s(_vm.content.title)}}):_vm._e(),_c('g-pattern',{staticClass:"pattern-bottom"})],1),_c('div',{ref:"duoContent",staticClass:"duo__content"},[(_vm.content.htmltext)?_c('g-wysiwyg',{attrs:{"wysiwyg":_vm.content.htmltext}}):_vm._e(),(_vm.content.links)?_c('ul',{staticClass:"duo__content__links"},_vm._l((_vm.content.links),function(link,i){return _c('li',{key:i,staticClass:"duo__content__link"},[_c('a',{staticClass:"pt-xxs pb-xxs",attrs:{"href":link.url,"target":link.target}},[_c('span',[_vm._v(_vm._s(link.label))]),_c('div',{staticClass:"icon-wrapper"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"icon",attrs:{"symbol":"ui-arrow-right","size":"0 0 24 24"}})])])])}),0):_vm._e(),(_vm.content.illustration)?_c('div',{staticClass:"duo__content__illustration"},[_c('img',{staticClass:"hero__picture",attrs:{"src":_vm.content.illustration.src,"alt":_vm.content.illustration.alt}})]):_vm._e(),_c('DuoServices',{attrs:{"content":_vm.content.services}})],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }