import { capitalize } from '@/inc/utils'

const MODIFIERS = ['shift', 'ctrl', 'alt', 'meta'] as const
enum ALIASES {
  up = 'ArrowUp',
  right = 'ArrowRight',
  left = 'ArrowLeft',
  down = 'ArrowDown',
  pageup = 'PageUp',
  pagedown = 'PageDown',
  space = 'Space',
  tab = 'Tab',
}

type Modifier = typeof MODIFIERS[number]

export class KeyboardState {
  private keys: Record<KeyboardEvent['key'], boolean> = {}
  private modifiers: Record<Modifier, boolean> = {
    shift: false,
    ctrl: false,
    alt: false,
    meta: false,
  }
  public pressed = false

  constructor() {
    this.onKeyUp = this.onKeyUp.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)

    document.addEventListener('keydown', this.onKeyDown, false)
    document.addEventListener('keyup', this.onKeyUp, false)
  }

  private onKeyChange(event: KeyboardEvent, pressed: boolean) {
    if (event.defaultPrevented) {
      return // Do nothing if the event was already processed
    }
    const { key, shiftKey, ctrlKey, altKey, metaKey } = event

    // Keys update
    this.keys[key] = pressed

    // Modifiers update
    this.modifiers.shift = shiftKey
    this.modifiers.ctrl = ctrlKey
    this.modifiers.alt = altKey
    this.modifiers.meta = metaKey

    const keys = Object.values(this.keys)
    this.pressed = keys.length > 0 && keys.some(k => k === true)
  }

  private onKeyUp(event: KeyboardEvent) {
    !event.repeat && this.onKeyChange(event, false)
  }

  private onKeyDown(event: KeyboardEvent) {
    !event.repeat && this.onKeyChange(event, true)
  }

  public destroy() {
    document.removeEventListener('keydown', this.onKeyDown, false)
    document.removeEventListener('keyup', this.onKeyUp, false)
  }

  public pressedKey(keyDesc: string) {
    const keys = keyDesc.split('+')

    return keys.every((k: string | Modifier) => {
      if (MODIFIERS.includes(k as Modifier)) {
        return this.modifiers[k as Modifier]
      }

      if (k.length > 1) {
        if (Object.keys(ALIASES).includes(k)) {
          return this.keys[ALIASES[k as keyof typeof ALIASES]]
        }

        return this.keys[capitalize(k)]
      }

      return this.keys[k] || false
    })
  }
}
