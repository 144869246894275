import { computed, reactive } from '@vue/composition-api'
import breakpoints from '@/inc/utils/breakpoints.json'

const getMqWidth = (label: string) => {
  const bp = breakpoints[label]

  if (!bp) {
    console.warn('[composables/mq] Invalid label')

    return 0
  }

  return parseInt(bp.replace('px', ''), 10)
}

export const viewport = reactive({
  width: 0,
  height: 0,
  ratio: 1,
})

export const onResize = (w: number, h: number, r: number) => {
  viewport.width = w
  viewport.height = h
  viewport.ratio = r
}

export const isMoreThan = computed(
  () => (label: string) => viewport.width > getMqWidth(label)
)
export const isMoreThanOrEqual = computed(
  () => (label: string) => viewport.width >= getMqWidth(label)
)
export const isLessThan = computed(
  () => (label: string) => viewport.width < getMqWidth(label)
)
export const isLessThanOrEqual = computed(
  () => (label: string) => viewport.width <= getMqWidth(label)
)
