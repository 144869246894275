








import { defineComponent, ref, watch } from '@vue/composition-api'
import { isLoading } from '@/composables/loadingGlobal'

export default defineComponent({
  name: 'UILoadingGlobal',
  setup() {
    const delay = 250
    const showLoading = ref(false)
    const readyToHide = ref(true)
    let displayTimeout: ReturnType<typeof setTimeout>

    watch(isLoading, value => {
      if (value) {
        // Let's give a chance to fast operations (delay)
        displayTimeout = setTimeout(() => {
          showLoading.value = true
          readyToHide.value = false
          // Minimum loading duration display
          setTimeout(() => {
            if (readyToHide.value) {
              showLoading.value = false
            }

            readyToHide.value = true
          }, 1000)
        }, delay)
      } else {
        clearTimeout(displayTimeout)

        if (readyToHide.value) {
          showLoading.value = false
        } else {
          // Wait a minimum
          readyToHide.value = true
        }
      }
    })

    return { showLoading }
  },
})
