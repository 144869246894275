






































































import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { gsap } from 'gsap'
import Slidy, { TransitionInfos } from 'epic-slidy'

export default defineComponent({
  name: 'Keypoints',
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    let slider: Slidy
    const list = ref<HTMLElement | null>(null)
    const container = ref<HTMLElement | null>(null)
    const number = ref(1)
    const current = ref(0)
    const currentItem = ref<HTMLElement | null>(null)
    const tl = ref()
    tl.value = gsap.timeline()

    const prev = () => {
      slider.slidePrev('controls')
    }

    const next = () => {
      slider.slideNext('controls')
    }

    onMounted(() => {
      const items = list.value!.querySelectorAll('li')

      currentItem.value = items[current.value]

      number.value = Math.floor(
        list.value!.clientWidth / items[items.length - 1].clientWidth
      )

      const transition = (
        currentSlide: HTMLElement,
        newSlide: HTMLElement,
        infos,
        { newIndex }: TransitionInfos
      ) => {
        const tl = gsap.timeline()
        current.value = newIndex

        return tl
          .add('transition')
          .to(
            list.value,
            {
              x: currentItem.value!.offsetWidth * -current.value,
              duration: 0.5,
            },
            'transition'
          )
          .then()
      }

      const shouldInteract = props.content?.items.length > number.value

      slider = new Slidy(list.value as HTMLElement, {
        controls: false,
        click: false,
        drag: shouldInteract,
        namespace: 'kp-slider',
        swipe: shouldInteract,
        loop: false,
        transition,
      })

      slider.on('beforeSlide', infos => {
        if (infos.direction === 'prev') {
          gsap.set(slider.items[infos.newIndex], {
            opacity: 1,
          })
        } else {
          gsap.set(slider.items[infos.currentIndex], {
            opacity: 0,
          })
        }
      })

      tl.value.set(items, {
        opacity: 0,
        x: 300,
      })

      slider.init()
    })

    const onAppear = isInViewport => {
      const items = list.value!.querySelectorAll('li')

      if (isInViewport) {
        tl.value.to(
          items,
          {
            duration: 0.5,
            opacity: 1,
            stagger: 0.2,
            x: 0,
            ease: 'power4.out',
          },
          '<1'
        )
      }
    }

    onUnmounted(() => {
      if (slider) {
        slider.destroy()
      }
    })

    return {
      list,
      container,
      // OnResize,
      onAppear,
      prev,
      next,
      current,
      number,
    }
  },
})
