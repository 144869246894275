























import { defineComponent, computed, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { parseRoute } from '@/inc/utils'

import PingPong from '@/components/ping/Pong.vue'

export default defineComponent({
  name: 'not-found',
  components: {
    PingPong,
  },
  props: {
    customContent: Object,
    default: null,
  },
  setup(props, ctx) {
    const { $logger, $route } = ctx.root
    const { api, endpoint, resource } = parseRoute($route)
    const emoji = '¯\\_(ツ)_/¯'
    const score = ref(404)
    const content =
      props.customContent || useGetters(['chrome']).chrome.value.notFound
    const title = computed(() => `Erreur ${score.value}`)

    $logger.warn('[NotFound]', emoji)

    return {
      content,
      title,
      score,
      url: `${api}/${endpoint}/${resource}`,
      emoji,
    }
  },
})
