















import { defineComponent, ref } from '@vue/composition-api'
import ChromeHeaderNavChildren from '@/components/chrome/header/NavChildren.vue'

export default defineComponent({
  name: 'ChromeHeaderNav',
  props: {
    content: Array,
  },
  components: {
    ChromeHeaderNavChildren,
  },
  setup() {
    const isListOpen = ref(false)
    const toggleList = (e, f: MouseEvent) => {
      if (isListOpen.value === e) {
        close()
      } else {
        open(e, f)
      }
    }

    const close = () => {
      isListOpen.value = false
      window.removeEventListener('click', close)
    }

    const open = (e, f: MouseEvent) => {
      f.stopPropagation()
      isListOpen.value = e
      window.addEventListener('click', close)
    }

    return {
      close,
      toggleList,
      isListOpen,
    }
  },
})
