

















import { defineComponent } from '@vue/composition-api'
// Import { useGetters } from '@u3u/vue-hooks'

import Anchors from '@/components/Anchors.vue'
import BulletsDuo from '@/components/BulletsDuo.vue'
import Cta from '@/components/Cta.vue'
import CtasGrid from '@/components/CtasGrid.vue'
import Keypoints from '@/components/Keypoints.vue'
import LogosGallery from '@/components/LogosGallery.vue'
import MapDuo from '@/components/MapDuo.vue'
import RelatedNews from '@/components/RelatedNews.vue'
import TextDuo from '@/components/TextDuo.vue'
import Widgets from '@/components/Widgets.vue'
import WidgetDuo from '@/components/WidgetDuo.vue'

/* eslint-disable camelcase */
const components = {
  anchors: Anchors,
  bulletsDuo: BulletsDuo,
  cta: Cta,
  ctasGrid: CtasGrid,
  illustration: TextDuo,
  keypoints: Keypoints,
  logo_gallery: LogosGallery,
  mapDuo: MapDuo,
  relatedNews: RelatedNews,
  servicesDuo: TextDuo,
  textDuo: TextDuo,
  widgets: Widgets,
  widgetDuo: WidgetDuo,
}
/* eslint-enable camelcase */

export default defineComponent({
  name: 'GFlexible',
  props: {
    content: Object,
  },
  setup() {
    return {
      components,
      // ...useGetters(['content']),
    }
  },
})
