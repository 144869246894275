




import { defineComponent, ref, onMounted } from '@vue/composition-api'

export default defineComponent({
  name: 'DashboardWidget',
  props: {
    snippet: { type: String, required: true },
  },

  setup(_props, ctx) {
    const root = ref<HTMLElement>()

    onMounted(() => {
      if (!root.value) {
        return
      }

      const { firstElementChild: widget } = root.value

      if (
        ctx.root.$$type === 'client' &&
        widget &&
        widget.children.length === 0
      ) {
        window.stmlk_reRenderWidgets()
      }
    })

    return { root }
  },
})
