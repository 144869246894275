













import { defineComponent, ref, onMounted, PropType } from '@vue/composition-api'
import DuoHeader from '@/components/duo/Header.vue'

interface WidgetDuo {
  slug: string
  snippet: string
}

export default defineComponent({
  name: 'WidgetDuo',
  components: {
    DuoHeader,
  },
  props: {
    content: {
      type: Object as PropType<WidgetDuo>,
      default: () => ({} as Partial<WidgetDuo>),
    },
  },
  setup(_props, ctx) {
    const root = ref<HTMLElement>()

    onMounted(() => {
      if (!root.value) {
        return
      }

      const { firstElementChild: widget } = root.value

      if (
        ctx.root.$$type === 'client' &&
        widget &&
        widget.children.length === 0
      ) {
        window.stmlk_reRenderWidgets()
      }
    })

    return {
      root,
    }
  },
})
