















































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'Anchors',
  props: {
    content: Object,
  },
  setup() {
    const scrollTo = (selector: string) => {
      console.log('test', selector)
      const el = document.querySelector(selector) as HTMLElement

      window.scrollTo({
        top: el?.offsetTop,
        left: 0,
        behavior: 'smooth',
      })
    }

    return {
      scrollTo,
    }
  },
})
