import Vue, { VueConstructor } from 'vue'
import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { Ref, ref } from '@vue/composition-api'

import { RootState } from '@/inc/store'
import { QueryablePromise } from '@/inc/types'

export interface SidePanelState {
  all: SidePanel[]
  index: number
}

export interface SidePanel {
  id: number
  component: {
    slug?: string
    template?: VueConstructor<Vue>
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: QueryablePromise<Record<string, any>> | Record<string, any>
  isLoaded: Ref<boolean>
}

const state: SidePanelState = {
  all: [],
  index: 0,
}

const getters: GetterTree<SidePanelState, RootState> = {
  panels: state => state.all,
  hasPanels: state => state.all.length > 0,
}

const mutations: MutationTree<SidePanelState> = {
  ADD(state, panel: SidePanel) {
    state.index += 1
    panel.id = state.index
    state.all.push(panel)
  },
  REMOVE(state, panel: SidePanel) {
    state.all.splice(state.all.indexOf(panel), 1)
  },
  REMOVE_LAST(state) {
    state.all.pop()
  },
}

const actions: ActionTree<SidePanelState, RootState> = {
  showPanel({ commit }, panel: SidePanel) {
    panel.isLoaded = ref(true)

    if (panel.content.status) {
      panel.isLoaded.value = false
      const p = panel.content

      p.then(res => {
        panel.content = res.data.content
        panel.isLoaded.value = true
      }).catch(err => {
        console.error(err)
        panel.content = { title: 'Error… ' }
        panel.isLoaded.value = true
      })
      panel.content = {}
    }

    commit('ADD', panel)
    document.body.classList.add('no-scroll')
  },
  hidePanel({ commit }) {
    commit('REMOVE_LAST')
    document.body.classList.remove('no-scroll')
  },
}

const sidePanel: Module<SidePanelState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}

export default sidePanel
