<template>
  <div v-if="input" class="form-input">
    <input
      :id="input.id"
      :name="input.name"
      :type="input.type || 'text'"
      v-on:input="$emit('input', $event.target.value)"
      v-on:click="$emit('clicked')"
      :required="required"
      v-model="value"
      class="form-input__field"
      :class="{ 'is-filled': hasValue }"
      :autocomplete="input.autocomplete === false ? 'off' : null"
    />

    <label v-if="input.label" :for="input.id" class="form-input__label">
      {{ input.label }}
    </label>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'form-input',
  data() {
    return {
      value: '',
    }
  },

  computed: {
    hasValue() {
      return Boolean(this.value.length)
    },
  },

  props: ['input', 'required'],
})
</script>

<style lang="scss" scoped>
.form-input,
[class*='form-input--'] {
  position: relative;
  padding: 1rem 0;
}

.form-input__label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem 0;
  color: $c-gray;
  font-size: 1.8rem;
  transition: top $ease 0.1s, font-size $ease 0.1s;
  pointer-events: none;
}

.form-input__field {
  @extend %fw-bold;

  width: 100%;
  padding-left: 0;
  background: none;
  border: 0;
  border-bottom: 0.3rem solid $c-black;

  &:focus,
  &.is-filled {
    + .form-input__label {
      top: -$spacing;
      color: $c-gray;
      font-size: 1rem;
      pointer-events: none;
    }
  }
}
</style>
